import React, { useState, useEffect } from 'react';
import anxiety from '../../Assets/anxiety.jpg'
import angermanagement from '../../Assets/Anger Management.jpg'
import behaviour from '../../Assets/Behavioral Issues.jpg'
import depression from '../../Assets/Depression.jpg'
import grief from '../../Assets/Grief.jpg'
import medication from '../../Assets/Medication Management.jpg'
import relationship from '../../Assets/Relationship Issues.jpg'
import truama from '../../Assets/Trauma and PTSD.jpg'
import ADHD from '../../Assets/ADHD.jpg'
import {motion} from 'framer-motion';
import {fadeIn} from '../../Variants'
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import 'react-phone-number-input/style.css'; // Import react-phone-number-input CSS
import PhoneInput from 'react-phone-number-input';
import Calendar from '../../Assets/Calendar.svg'
function Services() {
  const [showModal, setShowModal] = useState(true); // Start with modal shown
  const navigate = useNavigate(); 
  const [values, setValues] = useState({
    FullName: '',
    PhoneNumber: '',
    EmailAddress: '',
    Age: ''
  });


 // Handle modal visibility timeout
 const handleCloseModal = () => {
  setShowModal(false);
  setTimeout(() => {
    setShowModal(true);
  }, 180000); // 180000 ms = 3 minutes
};

function handleSubmit(e) {
  e.preventDefault();
  axios
    .post(
      'https://phoebe-compass-point-560accfa31d6.herokuapp.com/add_Appointment',
      values
    )
    .then((res) => {
      // On success, navigate to a success page or handle success
      navigate('/Success'); // Change this route to your success page
    })
    .catch((err) => {
      console.error('Error adding appointment:', err);
      // On error, navigate to the error component
      navigate('/Unsuccess'); // Change this route to your error component
    });
}




  return (
    <section class="py-10 bg-gradient-to-b from-green-50 to-green-100 sm:py-16 lg:py-16">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-2xl mx-auto text-center">
            <motion.h2 
             variants={fadeIn("down",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
            class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl font-serif">Services We Offer</motion.h2>
            <motion.p
              variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
             class="mt-2 text-base font-Montserrat text-gray-800 sm:text-xl font-montserrat">At Phoebe Compass Point, we specialize in addressing a variety of mental health concerns through personalized care. Here’s an overview of the services we provide</motion.p>
        </div>



            <div class="grid max-w-md grid-cols-1 mx-auto mt-12 lg:max-w-full lg:mt-16 lg:grid-cols-3 gap-x-16 gap-y-12">
            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={anxiety} alt="" />
                </div>
                <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                     href="#" title="" class="text-black font-serif"> Anger Management </motion.a>
                </p>
                <motion.p
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                 class="mt-4 text-gray-600 font-montserrat font-medium">Our anger management program helps individuals understand the root causes of their anger, develop healthier coping mechanisms, and learn practical skills to manage their emotions in challenging situations.</motion.p>
            </div>

            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={angermanagement} alt="" />
                </div>
              <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a 
                            variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                    href="#" title="" class="text-black font-serif"> Anxiety </motion.a>
                </p>
                <motion.p
                     variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                 class="mt-4 text-gray-600 font-montserrat font-medium">We offer treatments to help individuals manage anxiety, whether it’s generalized anxiety, social anxiety, or panic disorders. Through therapy and medication management, we aim to reduce symptoms and improve overall quality of life.</motion.p>
            </div>

            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={behaviour} alt="" />
                </div>
              <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}  href="#" title="" class="text-black font-serif"> Behavioral Issues 
                    </motion.a>
                </p>
                <motion.p
                  variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} class="mt-4 text-gray-600 font-montserrat font-medium">We address a wide range of behavioral issues, helping individuals especially children and adolescents manage impulsive or disruptive behaviors, improve emotional regulation, and develop better social skills.
                </motion.p>
                
                
            </div>




            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={depression} alt="" />
                </div>
              <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} href="#" title="" class="text-black font-serif"> Depression 
                    </motion.a>
                </p>
                <motion.p
                  variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} class="mt-4 text-gray-600 font-montserrat font-medium">Our depression treatment focuses on comprehensive care, from diagnosing the root causes to developing personalized therapy plans. We provide support for those struggling with mood disorders, helping them regain a sense of hope and stability.
                </motion.p>
                
                
            </div>


            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={grief} alt="" />
                </div>
              <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} href="#" title="" class="text-black font-serif"> Grief 
                    </motion.a>
                </p>
                <motion.p
                  variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} class="mt-4 text-gray-600 font-montserrat font-medium">Our grief counseling helps individuals navigate the emotional pain of losing a loved one, offering support and tools to process emotions, find closure, and eventually move toward healing.
                </motion.p>
                
                
            </div>


            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={medication} alt="" />
                </div>
              <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} href="#" title="" class="text-black font-serif"> Medication Management
                     </motion.a>
                </p>
                <motion.p
                  variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} class="mt-4 text-gray-600 font-montserrat font-medium">For those who require medication as part of their mental health treatment, we offer ongoing medication management services, ensuring safe and effective use while monitoring for side effects and adjusting dosages as needed.
                </motion.p>
                
                
            </div>

            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={relationship} alt="" />
                </div>
              <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} href="#" title="" class="text-black font-serif"> Relationship Issues 
                    </motion.a>
                </p>
                <motion.p
                  variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} class="mt-4 text-gray-600 font-montserrat font-medium">We provide therapy to couples or individuals struggling with relationship issues, whether related to communication, trust, or intimacy. Our goal is to help you build stronger, healthier connections.
                </motion.p>
                
                
            </div>

            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={truama} alt="" />
                </div>
              <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} href="#" title="" class="text-black font-serif"> Trauma and PTSD 
                    </motion.a>
                </p>
                <motion.p
                  variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} class="mt-4 text-gray-600 font-montserrat font-medium">For those suffering from trauma or post-traumatic stress disorder (PTSD), we offer trauma-focused therapies designed to help individuals process and heal from their experiences, reclaiming control over their lives.
                </motion.p>
                
                
            </div>


            <div>
                <div href="#" title="" class="block aspect-w-4 aspect-h-3">
                    <img class="object-cover w-full h-full" src={ADHD} alt="" />
                </div>
              <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-gray-600 mt-9"> Service Offered </span>
                <p class="mt-2 text-xl font-semibold">
                    <motion.a
                      variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} href="#" title="" class="text-black font-serif"> ADHD 
                    </motion.a>
                </p>
                <motion.p
                  variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}} class="mt-4 text-gray-600 font-montserrat font-medium">Our ADHD specialists offers Personalized treatment plans tailored to your specific needs
Medication management to help manage symptoms effectively
Support and resources to navigate challenges associated with ADHD

                </motion.p>
                
                
            </div>

        </div>


 {/* Modal Section */}
 {showModal && (
            <div className="modal modal-open">
  <div className="modal-box w-11/12 max-w-5xl rounded-none p-4">
  <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={handleCloseModal}>
                  ✕
                </button>
    <div className="max-w-2xl mt-2 p-4 mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-3xl font-serif">
              Set up Initial Appointment
            </h2>
            <p className="max-w-xl mx-auto mt-4 text-sm leading-relaxed text-gray-600 font-montserrat">
              Start Your Mental Wellness Journey Today Scheduling an Initial Assessment Consultation Now
            </p>
          </div>
          <div className="flex flex-col -mt-8 md:flex-row"> 
      {/* Left Side - Modal Content */}
      <div className="w-full md:w-1/2 p-6 flex items-center justify-center">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <form className="max-w-xl mx-auto mt-2" onSubmit={handleSubmit} action="#" method="POST">
            <div className="space-y-2">
              <div className="md:flex md:space-x-2">
                <div className="md:w-1/2">
                  <label htmlFor="FullName" className="text-base font-medium text-gray-900 font-serif">
                    Name
                  </label>
                  <div className="mt-2.5 relative">
                    <input
                      type="text"
                      name="FullName"
                      id="FullName"
                      placeholder="Full Name"
                      className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                      onChange={(e) => setValues({ ...values, FullName: e.target.value })}
                      required
                    />
                  </div>
                </div>

                <div className="md:w-1/2">
                  <label htmlFor="Age" className="text-base font-medium text-gray-900 font-serif">
                    Age
                  </label>
                  <div className="mt-2.5 relative">
                    <input
                      type="number"
                      id="Age"
                      name="Age"
                      onChange={(e) => setValues({ ...values, Age: e.target.value })}
                      className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                      placeholder="Enter Age"
                      required
                    />
                  </div>
                </div>
              </div>

              <div>
                <label htmlFor="PhoneNumber" className="text-base font-medium text-gray-900 font-serif">
                  Phone
                </label>
                <div className="mt-2.5 relative">
                  <PhoneInput
                    international
                    defaultCountry="US"
                    value={values.PhoneNumber}
                    onChange={(phoneNumber) => setValues({ ...values, PhoneNumber: phoneNumber })}
                    className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                    placeholder="Enter your Phone Number"
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="EmailAddress" className="text-base font-medium text-gray-900 font-serif">
                  Email address
                </label>
                <div className="mt-2.5 relative">
                  <input
                    type="text"
                    name="EmailAddress"
                    id="EmailAddress"
                    placeholder="Enter your Email Address"
                    className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                    onChange={(e) => setValues({ ...values, EmailAddress: e.target.value })}
                    required
                  />
                </div>
              </div>

              <div>
                <button type="submit" className="inline-flex items-center justify-center w-full px-4 py-3 text-sm font-semibold text-white transition-all duration-200 bg-[#4ADE80] border border-transparent rounded-md hover:bg-[#34C759] focus:bg-[#34C759]">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
                  </svg>
                  Schedule Appointment
                </button>
              </div>
            </div>
          </form>

          <div className="flex items-center justify-start mt-2 sm:justify-center sm:px-0">
            <svg className="flex-shrink-0 w-5 h-5 text-gray-600 hidden lg:block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"> {/* Hidden on small screens */}
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
            </svg>
            <span className="ml-2 text-sm text-gray-600 font-montserrat">
              Your data is completely secured with us. We don’t share it with anyone.
            </span>
          </div>
        </div>
      </div>

      {/* Right Side - SVG Image */}
      <div className="w-full md:w-1/2 flex items-center justify-center p-6">
        <img
          src={Calendar}
          alt="Mental Wellness Illustration"
          className="w-full h-auto hidden md:block" 
        />
      </div>
    </div>
  </div>
</div>


          )}


    </div>
</section>
  )
}

export default Services