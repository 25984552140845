import React from 'react'
import {motion} from 'framer-motion';
import {fadeIn} from '../../Variants'


function Payment() {
  return (
    <section class="py-10 bg-gray-900 sm:py-16 lg:py-24">
    <div class="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
        <div class="grid grid-cols-1 md:items-stretch md:grid-cols-2 gap-x-12 lg:gap-x-20 gap-y-10">
        <div class="flex flex-col justify-between lg:py-5">
                <div>
                    <motion.h2
                       variants={fadeIn("down",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                     class="text-3xl font-bold leading-tight text-white sm:text-4xl lg:leading-tight lg:text-5xl font-serif">Support Your Mental Health Journey with Compassionate Care
                    </motion.h2>
                    <motion.p
                       variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                     class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-white font-montserrat">
Support your mental health journey with care and compassion. Seeking help provides a safe space to explore your emotions and build resilience. Through therapy or mindfulness, prioritizing your mental well-being leads to greater peace and balance.
                    </motion.p>

                  
                </div>
            </div>

            <div class="">
                <div class="overflow-hidden bg-white rounded-md">
                    <div class="p-10">
                        <h3 class="text-xs font-semibold tracking-widest bg-gray-300 inline-block px-4 py-2 rounded-full text-black uppercase">Types of Payment we Accept</h3>
                        <ul class="flex flex-col mt-8 space-y-4 font-montserrat">
                  <li class="inline-flex items-center space-x-2">
                    <svg class="flex-shrink-0 w-5 h-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-base font-medium text-gray-900"> Access to Licensed Therapists </span>
                  </li>

                  <li class="inline-flex items-center space-x-2">
                    <svg class="flex-shrink-0 w-5 h-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-base font-medium text-gray-900"> Personalized Treatment Plans </span>
                  </li>

                  <li class="inline-flex items-center space-x-2">
                    <svg class="flex-shrink-0 w-5 h-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-base font-medium text-gray-900"> Confidential Support </span>
                  </li>

                  <li class="inline-flex items-center space-x-2">
                    <svg class="flex-shrink-0 w-5 h-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                    </svg>
                    <span class="text-base font-medium text-gray-900"> Ongoing Therapy Sessions </span>
                  </li>
                </ul>
                <div class="space-y-3 mt-4 sm:mt-8">
    <div class="overflow-hidden transition-all duration-200 bg-white border-2 border-gray-200 rounded-md hover:bg-gray-50">
        <div class="px-4 py-5 sm:p-6">
            <div class="flex items-center">
                <div>
                    <div href="#" class="text-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
</svg>
                    </div>
                </div>
                <label for="monthlyPlan" class="ml-5 mr-auto">
                    <p class="text-xl font-semibold text-black font-montserrat">Cash Payment</p>
                    <p class="text-sm text-gray-600 font-montserrat">Simple billing per Appointment</p>
                </label>
            </div>
        </div>
    </div>

    <div class="overflow-hidden transition-all duration-200 bg-white border-2 rounded-md hover:bg-gray-50">
        <div class="px-4 py-5 sm:p-6">
            <div class="flex items-center">
                <div>
                    <div href="#" class="text-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
</svg>

                    </div>
                </div>
                <label for="yearlyPlan" class="ml-5 mr-auto">
                    <p class="text-xl font-semibold text-black font-montserrat">Card Payment</p>
                    <p class="text-sm text-gray-600 font-montserrat">Fast and Secure Transaction</p>
                </label>
            </div>
        </div>
    </div>
</div>


                       
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


  )
}

export default Payment