import React, { useState, useEffect } from 'react';
import hero from '../../Assets/Layer 2.png';
import { motion } from 'framer-motion';
import { fadeIn } from '../../Variants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import 'react-phone-number-input/style.css'; // Import react-phone-number-input CSS
import PhoneInput from 'react-phone-number-input';
import Calendar from '../../Assets/Calendar.svg'
function Hero() {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate(); 
  const [values, setValues] = useState({
    FullName: '',
    PhoneNumber: '',
    EmailAddress: '',
    Age: ''
  });

  // Check if modal was shown before
  useEffect(() => {
    const hasModalBeenShown = localStorage.getItem('modalShown');
    if (!hasModalBeenShown) {
      setShowModal(true);
    }
  }, []);

  const closeModal = async () => {
    try {
      await axios.post('https://phoebe-compass-point-560accfa31d6.herokuapp.com/api/visitor/increment');
      setShowModal(false);
      // Set a flag in localStorage so the modal doesn't show again
      localStorage.setItem('modalShown', 'true');
    } catch (error) {
      console.error('Error incrementing visitor count:', error);
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    axios
      .post(
        'https://phoebe-compass-point-560accfa31d6.herokuapp.com/add_Appointment',
        values
      )
      .then((res) => {
        // On success, navigate to a success page or handle success
        navigate('/Success'); // Change this route to your success page
      })
      .catch((err) => {
        console.error('Error adding appointment:', err);
        // On error, navigate to the error component
        navigate('/Unsuccess'); // Change this route to your error component
      });
  }

  return (
    <div className="bg-gradient-to-b from-green-50 to-green-100">
      <section className="py-10 sm:py-16 lg:py-24">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
            <div>
              <motion.h1
                variants={fadeIn('down', 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.7 }}
                className="text-4xl font-bold text-black font-serif sm:text-6xl lg:text-5xl"
              >
                Phoebe Compass Point Your Partner in
                <div className="relative inline-flex">
                  <span className="absolute inset-x-0 bottom-0 border-b-[30px] border-[#4ADE80]"></span>
                  <h1 className="relative text-4xl font-bold text-black sm:text-6xl lg:text-6xl">
                    Mental Wellness.
                  </h1>
                </div>
              </motion.h1>

              <motion.p
                variants={fadeIn('zoom', 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.7 }}
                className="mt-8 text-base font-Montserrat text-gray-800 sm:text-xl"
              >
                At Phoebe Compass Point, we believe that mental health is an
                integral part of overall well-being. We understand that finding
                the right support and treatment can be challenging. That’s why
                we are committed to offering compassionate and personalized
                mental health care to individuals in need. We offer services for
                adults 18 and older and are accepting new patients.
              </motion.p>

              <div className="grid grid-cols-1 px-20 mt-12 text-left gap-x-12 gap-y-8 sm:grid-cols-3 sm:px-0">
                {/* Feature list */}
                <motion.div
                  variants={fadeIn('zoom', 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: false, amount: 0.7 }}
                  className="flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-12 w-12"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                    />
                  </svg>
                  <p className="ml-3 text-sm font-semibold text-gray-800 font-montserrat">
                    Improved Mental health
                  </p>
                </motion.div>

                <motion.div
                  variants={fadeIn('zoom', 0.4)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: false, amount: 0.7 }}
                  className="flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-12 w-12"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z"
                    />
                  </svg>

                  <p className="ml-3 text-sm font-semibold text-gray-800 font-montserrat">
                    Mental health checkups
                  </p>
                </motion.div>

                <motion.div
                  variants={fadeIn('zoom', 0.6)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: false, amount: 0.7 }}
                  className="flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-12 w-12"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.05 4.575a1.575 1.575 0 1 0-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 0 1 3.15 0v1.5m-3.15 0 .075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 0 1 3.15 0V15M6.9 7.575a1.575 1.575 0 1 0-3.15 0v8.175a6.75 6.75 0 0 0 6.75 6.75h2.018a5.25 5.25 0 0 0 3.712-1.538l1.732-1.732a5.25 5.25 0 0 0 1.538-3.712l.003-2.024a.668.668 0 0 1 .198-.471 1.575 1.575 0 1 0-2.228-2.228 3.818 3.818 0 0 0-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0 1 16.35 15m.002 0h-.002"
                    />
                  </svg>

                  <p className="ml-3 text-sm font-semibold text-gray-800 font-montserrat">
                    Secure online support
                  </p>
                </motion.div>
              </div>
            </div>

            {/* Background Image */}
            <motion.div
              variants={fadeIn('zoom', 0.4)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: false, amount: 0.7 }}
              className="relative"
            >
              <img src={hero} alt="Hero" className="w-full h-auto" />
            </motion.div>
          </div>

          {/* Modal Section */}
          {showModal && (
            <div className="modal modal-open">
  <div className="modal-box w-11/12 max-w-5xl rounded-none p-4">
    <button
      className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
      onClick={closeModal}
    >
      ✕
    </button>
    <div className="max-w-2xl mt-2 p-4 mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-3xl font-serif">
              Set up Initial Appointment
            </h2>
            <p className="max-w-xl mx-auto mt-4 text-sm leading-relaxed text-gray-600 font-montserrat">
              Start Your Mental Wellness Journey Today Scheduling an Initial Assessment Consultation Now
            </p>
          </div>
          <div className="flex flex-col -mt-8 md:flex-row"> 
      {/* Left Side - Modal Content */}
      <div className="w-full md:w-1/2 p-6 flex items-center justify-center">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <form className="max-w-xl mx-auto mt-2" onSubmit={handleSubmit} action="#" method="POST">
            <div className="space-y-2">
              <div className="md:flex md:space-x-2">
                <div className="md:w-1/2">
                  <label htmlFor="FullName" className="text-base font-medium text-gray-900 font-serif">
                    Name
                  </label>
                  <div className="mt-2.5 relative">
                    <input
                      type="text"
                      name="FullName"
                      id="FullName"
                      placeholder="Full Name"
                      className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                      onChange={(e) => setValues({ ...values, FullName: e.target.value })}
                      required
                    />
                  </div>
                </div>

                <div className="md:w-1/2">
                  <label htmlFor="Age" className="text-base font-medium text-gray-900 font-serif">
                    Age
                  </label>
                  <div className="mt-2.5 relative">
                    <input
                      type="number"
                      id="Age"
                      name="Age"
                      onChange={(e) => setValues({ ...values, Age: e.target.value })}
                      className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                      placeholder="Enter Age"
                      required
                    />
                  </div>
                </div>
              </div>

              <div>
                <label htmlFor="PhoneNumber" className="text-base font-medium text-gray-900 font-serif">
                  Phone
                </label>
                <div className="mt-2.5 relative">
                  <PhoneInput
                    international
                    defaultCountry="US"
                    value={values.PhoneNumber}
                    onChange={(phoneNumber) => setValues({ ...values, PhoneNumber: phoneNumber })}
                    className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                    placeholder="Enter your Phone Number"
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="EmailAddress" className="text-base font-medium text-gray-900 font-serif">
                  Email address
                </label>
                <div className="mt-2.5 relative">
                  <input
                    type="text"
                    name="EmailAddress"
                    id="EmailAddress"
                    placeholder="Enter your Email Address"
                    className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                    onChange={(e) => setValues({ ...values, EmailAddress: e.target.value })}
                    required
                  />
                </div>
              </div>

              <div>
                <button type="submit" className="inline-flex items-center justify-center w-full px-4 py-3 text-sm font-semibold text-white transition-all duration-200 bg-[#4ADE80] border border-transparent rounded-md hover:bg-[#34C759] focus:bg-[#34C759]">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
                  </svg>
                  Schedule Appointment
                </button>
              </div>
            </div>
          </form>

          <div className="flex items-center justify-start mt-2 sm:justify-center sm:px-0">
            <svg className="flex-shrink-0 w-5 h-5 text-gray-600 hidden lg:block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"> {/* Hidden on small screens */}
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
            </svg>
            <span className="ml-2 text-sm text-gray-600 font-montserrat">
              Your data is completely secured with us. We don’t share it with anyone.
            </span>
          </div>
        </div>
      </div>

      {/* Right Side - SVG Image */}
      <div className="w-full md:w-1/2 flex items-center justify-center p-6">
        <img
          src={Calendar}
          alt="Mental Wellness Illustration"
          className="w-full h-auto hidden md:block" 
        />
      </div>
    </div>
  </div>
</div>


          )}
        </div>
      </section>
    </div>
  );
}

export default Hero;
