import React, { useState, useEffect } from 'react';
import LOGO from '../../Assets/Logo.png';
import { useNavigate, useLocation } from 'react-router-dom';

function Navbar() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('/'); // State to track active link

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const navigate = useNavigate();
  const location = useLocation();

  // Scroll to top whenever the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Handle Navigation and Close Mobile Menu
  const handleNavigation = (path) => {
    setActiveLink(path); // Set the active link when navigating
    navigate(path);
    if (isMobileMenuOpen) {
      setMobileMenuOpen(false); // Close the mobile menu after navigation
    }
  };

  return (
    <header className="sticky top-0 z-50 pb-6 shadow-lg bg-white lg:pb-0">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <nav className="flex items-center justify-between h-16 lg:h-20">
          <div className="flex-shrink-0">
            <div  title="" className="flex">
              <img className="w-auto h-8 lg:h-16 bg-transparent" src={LOGO} alt="Logo" />
            </div>
          </div>

          <button
            type="button"
            onClick={toggleMobileMenu}
            className="inline-flex p-2 text-black transition-all duration-200 rounded-md lg:hidden focus:bg-gray-100 hover:bg-gray-100"
          >
            {/* Hamburger icon */}
            <svg className={`w-6 h-6 ${isMobileMenuOpen ? 'hidden' : 'block'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8h16M4 16h16" />
            </svg>

            {/* Close icon */}
            <svg className={`w-6 h-6 ${isMobileMenuOpen ? 'block' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <div className="hidden lg:flex lg:items-center lg:ml-auto lg:space-x-10">
            {['/', '/About', '/Services', '/Contact'].map((path, index) => (
              <a
                key={index}
                href="#"
                onClick={() => handleNavigation(path)}
                className={`font-medium text-black transition-all duration-200 hover:text-[#4ADE80] focus:text-[#4ADE80] hover:border-b-2 focus:border-b-2 ${
                  activeLink === path ? 'text-[#4ADE80] border-b-2 border-[#4ADE80]' : 'hover:border-[#4ADE80] focus:border-[#4ADE80]'
                } font-Montserrat`}
              >
                {path === '/' ? 'Home' : path.substring(1)} {/* Display name */}
              </a>
            ))}
          </div>

          <a
            href="#"
            onClick={() => handleNavigation('/Insurance')}
            className="hidden items-center justify-center px-4 py-3 ml-10 text-base rounded-md font-semibold text-white transition-all duration-200 bg-[#4ADE80] border border-transparent lg:inline-flex hover:bg-[#34C759] focus:bg-[#34C759] font-Montserrat"
            role="button"
          >
            Insurance & Payment
          </a>
        </nav>

        {/* Mobile menu */}
        {isMobileMenuOpen && (
          <nav className="pt-4 pb-6 bg-white border border-gray-200 rounded-md shadow-md lg:hidden">
            <div className="flow-root">
              <div className="flex flex-col px-6 -my-2 space-y-1 text-center">
                {['/', '/About', '/Services', '/Contact'].map((path, index) => (
                  <a
                    key={index}
                    href="#"
                    onClick={() => handleNavigation(path)}
                    className={`inline-flex font-montserrat py-2 text-base font-medium text-black transition-all duration-200 hover:text-[#4ADE80] focus:text-[#4ADE80] hover:border-b-2 focus:border-b-2 ${
                      activeLink === path ? 'text-[#4ADE80] border-b-2 border-[#4ADE80]' : 'hover:border-[#4ADE80] focus:border-[#4ADE80]'
                    }`}
                  >
                    {path === '/' ? 'Home' : path.substring(1)}
                  </a>
                ))}
              </div>
            </div>

            <div className="px-6 mt-6 flex align-center justify-center">
              <a
                href="#"
                onClick={() => handleNavigation('/Insurance')}
                className="inline-flex w-full font-montserrat justify-center px-4 py-3 text-base rounded-md font-semibold text-white transition-all duration-200 bg-[#4ADE80] border border-transparent hover:bg-[#34C759] focus:bg-[#34C759]"
                role="button"
              >
                Insurance & Payment
              </a>
            </div>
          </nav>
        )}
      </div>
    </header>
  );
}

export default Navbar;
