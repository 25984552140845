import React, { useState, useEffect } from 'react';
import Logo from '../../Assets/Logo.png';
import schedule from '../../Assets/schedule.png';
import schedule2 from '../../Assets/schedule2.png';
import Instruction from '../../Assets/Instruction.svg';

function Success() {
  useEffect(() => {
    window.gtag('event', 'conversion', {
      send_to: 'AW-16727041545/QFR4CPTx5tsZEInMiag-'
    });
  }, []);

  const slides = [
    {
      content: (
        <div className="relative flex flex-col items-center max-w-5xl mx-auto sm:px-6 lg:px-8">
        {/* Main Content */}
        <div className="flex-grow grid items-center grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h2 className="text-2xl font-bold leading-tight text-black sm:text-3xl lg:text-4xl font-serif">
            Follow these Instruction to set your Virtual Appointment
            </h2>
            <p className="mt-4 text-sm leading-relaxed text-gray-600 sm:text-md md:text-lg font-montserrat">
            We’re thrilled to assist you in getting started with your virtual appointment! Please follow these straightforward steps to seamlessly schedule your appointment and ensure a smooth experience.
            </p>
          </div>
          <div className="relative">
            <img className=" w-full h-auto object-cover" src={Instruction} alt="Instruction" />
          </div>
        </div>

        {/* Left and Right Arrows */}
        <div className="flex justify-center items-center gap-2 mt-6 md:gap-0 md:justify-between w-full md:mt-0">
          <div
            className="cursor-pointer hover:bg-gray-200 rounded-full p-1 md:absolute md:-left-5 md:top-1/2 transform md:-translate-y-1/2"
            onClick={() => setActiveSlide((activeSlide - 1 + slides.length) % slides.length)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </div>

          <div
            className="cursor-pointer hover:bg-gray-200 rounded-full p-1 md:absolute md:-right-5 md:top-1/2 transform md:-translate-y-1/2"
            onClick={() => setActiveSlide((activeSlide + 1) % slides.length)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        </div>
      </div>
      


      
      
      
      ),
    },

    {
      content: (
        <div className="relative flex flex-col items-center max-w-5xl mx-auto sm:px-6 lg:px-8">
          {/* Main Content */}
          <div className="flex-grow grid items-center grid-cols-1 md:grid-cols-2 gap-8">
            <div>
            <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-green-600 mb-4 "> Step 1 </span>
              <h2 className="text-2xl font-bold leading-tight text-black sm:text-3xl lg:text-4xl font-serif">
              Schedule Your Virtual Session
              </h2>
              <p className="mt-4 text-sm leading-relaxed text-gray-600 sm:text-md md:text-lg font-montserrat">
              Choose Your Date and Time that works best for you from our available slots and after that Click the Book Session Button below.
              </p>
            </div>
            <div className="relative">
              <img className="border-2 border-gray-200 w-full h-auto object-cover" src={schedule} alt="Instruction" />
            </div>
          </div>

          {/* Left and Right Arrows */}
          <div className="flex justify-center items-center gap-2 mt-6 md:gap-0 md:justify-between w-full md:mt-0">
            <div
              className="cursor-pointer hover:bg-gray-200 rounded-full p-1 md:absolute md:-left-5 md:top-1/2 transform md:-translate-y-1/2"
              onClick={() => setActiveSlide((activeSlide - 1 + slides.length) % slides.length)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </div>

            <div
              className="cursor-pointer hover:bg-gray-200 rounded-full p-1 md:absolute md:-right-5 md:top-1/2 transform md:-translate-y-1/2"
              onClick={() => setActiveSlide((activeSlide + 1) % slides.length)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </div>
          </div>
        </div>
      ),
    },

    {
      content: (
        <div className="relative flex flex-col items-center max-w-5xl mx-auto sm:px-6 lg:px-8">
          {/* Main Content */}
          <div className="flex-grow grid items-center grid-cols-1 md:grid-cols-2 gap-8">
            <div>
            <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-green-600 mb-4"> Step 2 </span>
              <h2 className="text-2xl font-bold leading-tight text-black sm:text-3xl lg:text-4xl font-serif">
              Provide Your Personal Information
              </h2>
              <p className="mt-4 text-sm leading-relaxed text-gray-600 sm:text-md md:text-lg font-montserrat">
              After scheduling, Fill out the required fields with your personal details, including your First Name ,Last Name, Email, and Preferred Password
              to set up and register your account.
              </p>
            </div>
            <div className="relative">
              <img className="border-2 border-gray-200 w-full h-auto object-cover" src={schedule2} alt="Instruction" />
            </div>
          </div>

          {/* Left and Right Arrows */}
          <div className="flex justify-center items-center gap-2 mt-6 md:gap-0 md:justify-between w-full md:mt-0">
            <div
              className="cursor-pointer hover:bg-gray-200 rounded-full p-1 md:absolute md:-left-5 md:top-1/2 transform md:-translate-y-1/2"
              onClick={() => setActiveSlide((activeSlide - 1 + slides.length) % slides.length)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </div>

            <div
              className="cursor-pointer hover:bg-gray-200 rounded-full p-1 md:absolute md:-right-5 md:top-1/2 transform md:-translate-y-1/2"
              onClick={() => setActiveSlide((activeSlide + 1) % slides.length)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </div>
          </div>
        </div>
      ),
    },

    {
      content: (
        <div className="relative flex flex-col items-center max-w-5xl mx-auto sm:px-6 lg:px-8">
          {/* Main Content */}
          <div className="flex-grow grid items-center grid-cols-1 md:grid-cols-2 gap-8">
            <div>
            <span class="inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full text-gray-100 bg-green-600 mb-4"> Step 3 </span>
              <h2 className="text-2xl font-bold leading-tight text-black sm:text-3xl lg:text-4xl font-serif">
              Click Sign Up Button to Confirm
              </h2>
              <p className="mt-4 text-sm leading-relaxed text-gray-600 sm:text-md md:text-lg font-montserrat">
              Click the signup button to confirm your session. This simple step secures your appointment and ensures you get the support you need.
              </p>
            </div>
            <div className="relative">
              <img className="border-2 border-gray-200 w-full h-auto object-cover" src={schedule2} alt="Instruction" />
            </div>
          </div>

          {/* Left and Right Arrows */}
          <div className="flex justify-center items-center gap-2 mt-6 md:gap-0 md:justify-between w-full md:mt-0">
            <div
              className="cursor-pointer hover:bg-gray-200 rounded-full p-1 md:absolute md:-left-5 md:top-1/2 transform md:-translate-y-1/2"
              onClick={() => setActiveSlide((activeSlide - 1 + slides.length) % slides.length)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </div>

            <div
              className="cursor-pointer hover:bg-gray-200 rounded-full p-1 md:absolute md:-right-5 md:top-1/2 transform md:-translate-y-1/2"
              onClick={() => setActiveSlide((activeSlide + 1) % slides.length)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </div>
          </div>
        </div>
      ),
    },

    {
      content: (
        <div className="relative flex flex-col items-center max-w-5xl mx-auto sm:px-6 lg:px-8">
          {/* Main Content */}
          <div className="flex justify-between items-center max-w-7xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <div className="flex items-center justify-center">
              <img alt="Logo" src={Logo} className="h-16 border-2 border-gray-200 w-auto ml-2 bg-white rounded-lg p-2" />
            </div>
            <h2 className="mt-8 text-3xl font-bold leading-tight text-black lg:mt-4 sm:text-4xl lg:text-3xl font-serif">
             You will be  Redirecting to the Headway Calendar website to Select your Schedule for Virtual Appointment.
            </h2>
            <p className="max-w-6xl mx-auto mt-4 text-md text-gray-600 md:mt-4 font-montserrat">
              You’re almost done! Click the button to be redirected to Headway Calendar and choose your preferred appointment Date & time.
            </p>
          </div>
        </div>

        </div>
      ),
    },

    // Add more slides here as needed
  ];

  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <div className="relative p-4 sm:p-6 md:p-8 lg:p-12">
      <div className="py-4">{slides[activeSlide].content}</div>

      {activeSlide === 4 && (
        <div className="flex justify-center mb-4">
          <button
            className="btn bg-green-600 text-white rounded hover:bg-green-700 mt-4 font-montserrat"
            onClick={() => window.open('https://headway.co/providers/febbie-mutindori?utm_source=pem&utm_medium=direct_link&utm_campaign=88474', '_blank')}
          >
            Select Date Appointment
          </button>
        </div>
      )}

      <div className="flex justify-center mt-6">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`h-1.5 mx-1 cursor-pointer transition-all duration-300 rounded-full ${
              index === activeSlide
                ? 'bg-green-600 w-6'
                : 'bg-gray-300 w-3 hover:bg-gray-500'
            }`}
            onClick={() => setActiveSlide(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default Success;
