import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import {motion} from 'framer-motion';
import {fadeIn} from '../../Variants'
function JoinourNewslatter() {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
  
 
    
      // Handle form submission
      const handleAllowClick = async (e) => {
        e.preventDefault(); // Prevent default form submission
        if (!email) {
          setErrorMessage('Email is required');
          return;
        }
      
        try {
          const response = await axios.post('https://phoebe-compass-point-560accfa31d6.herokuapp.com/api/newsletter', { email });
      
          if (response.status === 201) {
            await axios.post('https://phoebe-compass-point-560accfa31d6.herokuapp.com/api/visitor/increment');
      
            // SweetAlert for successful subscription
            await Swal.fire({
              icon: 'success',
              title: 'Thank you for subscribing!',
              text: 'You will now receive our newsletter.',
              confirmButtonText: 'Okay'
            });
          }
        } catch (error) {
          console.error('Error saving email:', error);
          setErrorMessage('Failed to subscribe. Please try again.');
        }
      };
      



  return (
    
<section class="py-10 bg-white sm:py-16 lg:py-16">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-2xl mx-auto text-center">
            <motion.h2 
             variants={fadeIn("down",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
            class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl font-serif">Join our Newsletter</motion.h2>
            <motion.p
             variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
             class="mt-2 text-base font-Montserrat text-gray-800 sm:text-xl font-montserrat"> Get the latest mental health tips, expert insights, news, and services to support your well-being. From self-care advice to stress management tools, our newsletter has it all. Join our community for better mental health!</motion.p>
        </div>

        <div className="mt-8 space-y-8 sm:space-y-0 sm:mt-12 sm:flex sm:items-center sm:flex-col md:space-y-0 md:space-x-20 lg:mt-12 md:flex-row md:justify-center lg:justify-center">
  <div className="flex flex-col items-center sm:flex-row sm:items-center md:items-start">
    <svg
      className="flex-shrink-0 text-green-400 w-7 h-7"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      />
    </svg>
    <div className="ml-4 text-center sm:text-left">
      <h3 className="text-xl font-semibold text-black font-montserrat">
        Expert Guidance
      </h3>
      <p className="mt-1.5 text-base text-gray-600 font-montserrat">
        Mental health tips
      </p>
    </div>
  </div>

  <div className="flex flex-col items-center sm:flex-row sm:items-center md:items-start">
    <svg
      className="flex-shrink-0 text-green-400 w-7 h-7"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      />
    </svg>
    <div className="ml-4 text-center sm:text-left">
      <h3 className="text-xl font-semibold text-black font-montserrat">
        Latest Research
      </h3>
      <p className="mt-1.5 text-base text-gray-600 font-montserrat">
        Wellness insights
      </p>
    </div>
  </div>

  <div className="flex flex-col items-center sm:flex-row sm:items-center md:items-start">
    <svg
      className="flex-shrink-0 text-green-400 w-7 h-7"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      />
    </svg>
    <div className="ml-4 text-center sm:text-left">
      <h3 className="text-xl font-semibold text-black font-montserrat">
        Self-Care Tips
      </h3>
      <p className="mt-1.5 text-base text-gray-600 font-montserrat">
        Easy practices
      </p>
    </div>
  </div>
</div>




                <form className="max-w-xl mx-auto mt-12" onSubmit={handleAllowClick}>
  <div className="sm:p-2 sm:bg-gray-200 sm:border-2 sm:border-transparent sm:rounded-full sm:focus-within:border-blue-600 sm:focus-within:ring-1 sm:focus-within:ring-blue-600">
    <div className="flex flex-col items-start sm:flex-row sm:justify-center">
      <div className="flex-1 w-full min-w-0">
        <div className="relative text-gray-400 focus-within:text-gray-600">
          <label htmlFor="email" className="sr-only"></label>
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
              ></path>
            </svg>
          </div>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter email address"
            className="block w-full py-4 pl-10 pr-4 text-base border-2 sm:border-0 border-gray-400 text-black placeholder-gray-500 transition-all duration-200 rounded-full focus:ring-0 caret-blue-600 font-montserrat"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>

      <button
        type="submit"
        className="inline-flex items-center justify-center w-full px-4 py-4 mt-4 font-semibold text-white transition-all duration-200 bg-[#4ADE80] border border-transparent rounded-full sm:ml-4 sm:mt-0 sm:w-auto hover:bg-[#34C759] focus:bg-[#34C759]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-5 h-5 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
          />
        </svg>
        Subscribe
      </button>
    </div>
  </div>
</form>

<div className='flex items-center justify-center'>
                  {errorMessage && (
                    <p className="text-red-500 mt-2">{errorMessage}</p>
                  )}
                </div>

                <div className="flex items-center justify-start mt-8 sm:justify-center sm:px-0">
                  <svg className="flex-shrink-0 w-5 h-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                  </svg>
                  <span className="ml-2 text-sm text-gray-600 font-montserrat">
                    Your data is completely secured with us. We don’t share it with anyone.
                  </span>
                </div>


    </div>
</section>




  )
}

export default JoinourNewslatter