import React, { useState ,useEffect} from 'react';
import LOGO from '../../Assets/Logo.png';
import { FaTwitter, FaLinkedin, FaYoutube, FaFacebook } from 'react-icons/fa';
import { AiFillInstagram } from "react-icons/ai";// Import the icons you need
import { useNavigate, useLocation } from 'react-router-dom';
function Footer() {

    const navigate = useNavigate();
    const location = useLocation();
  
    // Scroll to top whenever the route changes
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
  
    // Handle Navigation and Close Mobile Menu
    const handleNavigation = (path, close) => {
      navigate(path);
      if (close) {
        close(); // Close the mobile menu after navigation
      }
    };


   

  return (
 


    <section class="py-4 bg-gray-50 sm:pt-16 lg:pt-14">
    <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="grid grid-cols-2 md:col-span-3 lg:grid-cols-6 gap-y-16 gap-x-12">
            <div class="col-span-2 md:col-span-3 lg:col-span-2 lg:pr-8">
                <img class="w-auto h-16" src={LOGO} alt="" />

                <p class="text-base leading-relaxed text-gray-600 mt-7 font-montserrat">At Phoebe Compass Point, we are dedicated to providing compassionate, culturally sensitive, and personalized mental health care. </p>

     
            </div>

            <div>
                <p class="text-sm font-semibold tracking-widest text-gray-400 uppercase font-serif">Company</p>

                <ul class="mt-6 space-y-4">
                    <li>
                        <a href="#" onClick={() => handleNavigation('/')} title="" class="flex text-base text-black transition-all duration-200 hover:text-[#4ADE80] focus:text-[#4ADE80] font-montserrat"> Home </a>
                    </li>

                    <li>
                        <a href="#" onClick={() => handleNavigation('/About')} title="" class="flex text-base text-black transition-all duration-200 hover:text-[#4ADE80] focus:text-[#4ADE80] font-montserrat"> About </a>
                    </li>

                    <li>
                        <a href="#" onClick={() => handleNavigation('/Services')} title="" class="flex text-base text-black transition-all duration-200 hover:text-[#4ADE80] focus:text-[#4ADE80] font-montserrat"> Services </a>
                    </li>

                    <li>
                        <a href="#" onClick={() => handleNavigation('/Contact')} title="" class="flex text-base text-black transition-all duration-200 hover:text-[#4ADE80] focus:text-[#4ADE80] font-montserrat"> Contact </a>
                    </li>
                </ul>
            </div>

            <div>
                <p class="text-sm font-semibold tracking-widest text-gray-400 uppercase font-serif">Help</p>

                <ul class="mt-6 space-y-4">
                    <li>
                        <a   onClick={() => handleNavigation('/Insurance')} href="#" title="" class="flex text-base text-black transition-all duration-200 hover:text-[#4ADE80] focus:text-[#4ADE80] font-montserrat"> Partnership </a>
                    </li>

                    <li>
                        <a onClick={() => handleNavigation('/Contact')} href="#" title="" class="flex text-base text-black transition-all duration-200 hover:text-[#4ADE80] focus:text-[#4ADE80] font-montserrat"> Appointment</a>
                    </li>

                   
                    <li>
                        <a  onClick={()=>document.getElementById('my_modal_3').showModal()}  title="" class="cursor-pointer  text-base text-black transition-all duration-200 hover:text-[#4ADE80] focus:text-[#4ADE80] font-montserrat"> Terms & Condition </a>
                    </li>
                    <li>
                        <a  onClick={()=>document.getElementById('my_modal_4').showModal()}  title="" class="cursor-pointer  text-base text-black transition-all duration-200 hover:text-[#4ADE80] focus:text-[#4ADE80] font-montserrat"> Privacy Policy </a>
                    </li>
                </ul>
            </div>

            <dialog id="my_modal_3" className="modal">
  <div className="modal-box w-11/12 max-w-5xl rounded-none">
    <form method="dialog">
      {/* if there is a button in form, it will close the modal */}
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
    </form>
    <h3 className="font-bold text-2xl font-serif">Terms and Conditions</h3>

    <div className="py-4 font-montserrat max-h-96 overflow-y-auto">
      <p className="mb-4">
        Welcome to <strong>Phoebe Compass Point</strong>. These Terms and Conditions govern your access to and use of our website and services. By using our website, you agree to comply with and be bound by these terms.
      </p>
      <p className="mb-4">
        <strong>1. Use of Services</strong><br />
        Our services are designed to provide mental health resources and support. They are not a substitute for professional medical diagnosis, treatment, or therapy. You agree that your use of our website is voluntary and at your own risk.
      </p>
      <p className="mb-4">
        <strong>2. Eligibility</strong><br />
        By using our website, you confirm that you are at least 18 years old or have the legal capacity to enter into this agreement. If you are under 18, you may only use our services with the consent of a parent or legal guardian.
      </p>
      <p className="mb-4">
        <strong>3. User Conduct</strong><br />
        You agree not to use the website for any unlawful or fraudulent purposes or post harmful content.
      </p>
      <p className="mb-4">
        <strong>4. Health Disclaimer</strong><br />
        The information provided is for educational purposes only and should not be considered medical advice. Always consult a healthcare professional for medical issues.
      </p>
      <p className="mb-4">
        <strong>5. Intellectual Property</strong><br />
        All content is owned or licensed by <strong>Phoebe Compass Point</strong>. You may not use, copy, or distribute any material without permission.
      </p>
      <p className="mb-4">
        <strong>6. Limitation of Liability</strong><br />
        To the fullest extent permitted by law, <strong>Phoebe Compass Point</strong> shall not be liable for any damages arising from your use of or inability to use the website.
      </p>
      <p className="mb-4">
        <strong>7. Third-Party Links</strong><br />
        We are not responsible for third-party website content or practices that may be linked from <strong>Phoebe Compass Point</strong>.
      </p>
      <p className="mb-4">
        <strong>8. Modification of Terms</strong><br />
        We reserve the right to modify these Terms and Conditions at any time. Any changes will be posted on this page, and continued use of the website signifies your acceptance of the updated terms.
      </p>
      <p className="mb-4">
        <strong>9. Governing Law</strong><br />
        These terms are governed by the laws of [Jurisdiction]. Any disputes will be resolved in the courts of [Jurisdiction].
      </p>
      <p className="mb-4">
        <strong>10. Contact Us</strong><br />
        If you have any questions about these Terms and Conditions, please contact us at phoebecompasspoint@gmail.com.
      </p>
    </div>

   
  </div>
</dialog>




<dialog id="my_modal_4" className="modal">
  <div className="modal-box w-11/12 max-w-5xl rounded-none">
    <form method="dialog">
      {/* if there is a button in form, it will close the modal */}
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
    </form>

    
    <h3 className="font-bold text-2xl font-serif mt-6">Privacy Policy</h3>

    <div className="py-4 font-montserrat max-h-96 overflow-y-auto">
      <p className="mb-4">
        At <strong>Phoebe Compass Point</strong>, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website or use our services.
      </p>
      <p className="mb-4">
        <strong>1. Information We Collect</strong><br />
        We may collect personally identifiable information (PII) and non-personally identifiable information when you interact with our website, including your name, email address, and health-related information you provide.
      </p>
      <p className="mb-4">
        <strong>2. How We Use Your Information</strong><br />
        The information we collect is used to provide and improve our services, personalize your experience, and communicate with you about appointments and new features.
      </p>
      <p className="mb-4">
        <strong>3. How We Share Your Information</strong><br />
        We do not sell your personal information. We may share your information with licensed professionals, service providers, or as required by law.
      </p>
      <p className="mb-4">
        <strong>4. Security</strong><br />
        We use industry-standard encryption to protect your personal information. However, no data transmission over the internet can be guaranteed as completely secure.
      </p>
      <p className="mb-4">
        <strong>5. Cookies and Tracking</strong><br />
        We may use cookies and similar technologies to collect information about how you use our website. You can disable cookies through your browser settings.
      </p>
      <p className="mb-4">
        <strong>6. Your Rights</strong><br />
        You have the right to access, update, or delete your personal information. To exercise these rights, please contact us.
      </p>
      <p className="mb-4">
        <strong>7. Changes to This Policy</strong><br />
        We may update this Privacy Policy from time to time. Significant changes will be posted on our website.
      </p>
      <p className="mb-4">
        <strong>8. Contact Us</strong><br />
        If you have questions about this Privacy Policy, please contact us at phoebecompasspoint@gmail.com.
      </p>
    </div>
  </div>
</dialog>





            <div class="col-span-2 md:col-span-1 lg:col-span-2 lg:pl-8">
                <p class="text-sm font-semibold tracking-widest text-gray-400 uppercase font-serif">Follow us</p>
                <ul className="flex items-center space-x-3 mt-6">
            <li>
                <a
                    href="https://x.com/PhoebeCompass"
                    title="Twitter"
                    className="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-[#4ADE80] focus:bg-[#34C759]"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaTwitter className="w-4 h-4" />
                </a>
            </li>

            <li>
                <a
                    href="https://www.youtube.com/"
                    title="YouTube"
                    className="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-[#4ADE80] focus:bg-[#34C759]"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaYoutube className="w-4 h-4" />
                </a>
            </li>

            <li>
                <a
                    href="https://www.linkedin.com/"
                    title="LinkedIn"
                    className="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-[#4ADE80] focus:bg-[#34C759]"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaLinkedin className="w-4 h-4" />
                </a>
            </li>

            <li>
                <a
                    href="https://www.instagram.com/"
                    title="Instagram"
                    className="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-[#4ADE80] focus:bg-[#34C759]"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <AiFillInstagram className="w-4 h-4" />
                </a>
            </li>

            <li>
                <a
                    href="https://www.facebook.com/"
                    title="Facebook"
                    className="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-[#4ADE80] focus:bg-[#34C759]"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaFacebook className="w-4 h-4" />
                </a>
            </li>
        </ul>

         
            </div>
        </div>

        <hr class="mt-16 mb-10 border-gray-200" />

        <p class="text-sm text-center text-gray-600 font-montserrat">© Copyright 2024 All Rights Reserved by Phoebe Compass Point</p>
    </div>
</section>

  )
}

export default Footer