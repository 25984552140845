import Payment from '../../components/WebsiteComponents/Payment';
import Companies from '../../components/WebsiteComponents/Companies';
export const Insurance = () => {
    return (
        <> 
<Companies/>
<Payment/>
    </>
       
    );
}