import React from 'react';
import offer1 from '../../Assets/offer1.png';
import offer2 from '../../Assets/offer2.jpg';
import offer3 from '../../Assets/offer3.jpeg';
import offer4 from '../../Assets/offer4.jpg';
import offer5 from '../../Assets/offer5.jpg';

import offer7 from '../../Assets/offer7.jpeg';
import {motion} from 'framer-motion';
import {fadeIn} from '../../Variants'

function WhatweOffer() {
  return (
    <section className="py-10 bg-gray-50 sm:py-16 lg:py-12">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="flex items-end justify-between">
          <div className="flex-1 text-center lg:text-left">
            <motion.h2
             variants={fadeIn("down",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
            
             className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl font-serif">Types of Therapy We Offer</motion.h2>
            <motion.p
                variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
             className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600 lg:mx-0 font-montserrat font-medium">
              At Phoebe Compass Point, we understand that different therapeutic approaches can be beneficial depending on individual needs and preferences. We offer a range of therapies to address various mental health concerns and support our clients' diverse backgrounds.
            </motion.p>
          </div>
        </div>

        <div className="grid max-w-md grid-cols-1 gap-6 mx-auto mt-8 lg:mt-16 lg:grid-cols-3 lg:max-w-full">
          {[
            { src: offer1, title: 'Christian Counseling', label: 'Counseling', description: 'Christian Counseling integrates faith-based principles with traditional therapeutic techniques. It’s designed for individuals seeking to incorporate their Christian faith into their mental health journey.' },
            { src: offer2, title: 'Compassion-Focused Therapy', label: 'Medication', description: 'Compassion-Focused Therapy (CFT) is designed to help individuals develop self-compassion and resilience. This approach is particularly beneficial for those struggling with self-criticism, shame, or low self-esteem.' },
            { src: offer3, title: 'Culturally Sensitive Therapy', label: 'Sensitive Therapy', description: 'Culturally Sensitive Therapy recognizes and respects the diverse cultural backgrounds of clients. This approach tailors therapeutic interventions to align with the client\'s cultural values, beliefs, and practices.' },
            { src: offer4, title: 'Mindfulness-Based Cognitive Therapy (MBCT)', label: 'Cognitive Therapy', description: 'Mindfulness-Based Cognitive Therapy (MBCT) combines traditional cognitive therapy with mindfulness practices. MBCT helps individuals break the cycle of depressive relapse by teaching mindfulness techniques that promote present-moment awareness and acceptance.' },
            { src: offer5, title: 'Emotionally-Focused Therapy', label: 'Focused Therapy', description: 'Emotionally-Focused Therapy (EFT) is designed to help individuals and couples explore and express their emotions in a safe and constructive manner. This approach focuses on identifying and addressing emotional patterns that affect relationships and personal well-being.' },
            { src: offer7, title: 'Trauma-Focused Therapy', label: 'Trauma-Focused Therapy', description: 'Trauma-Focused Therapy is designed to help individuals process and heal from traumatic experiences. This approach includes various techniques to address the effects of trauma, such as Post-Traumatic Stress Disorder (PTSD) and other trauma-related symptoms.' },
          ].map((offer, index) => (
            <div key={index} className="overflow-hidden bg-white rounded shadow">
              <div className="p-5">
                <div className="relative">
                  <div  title="" className="block w-full h-64">
                    <img className="object-cover w-full h-full" src={offer.src} alt={offer.title} />
                  </div>
                  <div className="absolute top-4 left-4">
                    <span className="px-4 py-2 text-xs font-semibold tracking-widest text-gray-900 uppercase bg-white rounded-full font-montserrat">{offer.label}</span>
                  </div>
                </div>
                <motion.p
              variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                
                 className="mt-5 text-2xl font-semibold">
                  <div  title="" className="text-black font-serif">{offer.title}</div>
                </motion.p>
                <motion.p
                  variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                 className="mt-4 text-base text-gray-600 font-montserrat font-medium">{offer.description}</motion.p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default WhatweOffer;
