import React, { useState } from 'react';
import 'flatpickr/dist/themes/material_green.css'; // Import a Flatpickr theme
import { motion } from 'framer-motion';
import { fadeIn } from '../../Variants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import schedule from '../../Assets/schedule.png';
import schedule2 from '../../Assets/schedule2.png';
import 'react-phone-number-input/style.css'; // Import react-phone-number-input CSS
import PhoneInput from 'react-phone-number-input';
import Logo from '../../Assets/Logo.png';

const Contactus = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const [values, setValues] = useState({
    FullName: '',
    PhoneNumber: '',
    EmailAddress: '',
    Age: ''
  });

  // Function to handle form submission
  function handleSubmit(e) {
    e.preventDefault();

    axios
      .post(
        'https://phoebe-compass-point-560accfa31d6.herokuapp.com/add_Appointment',
        values
      )
      .then((res) => {
        // On success, navigate to a success page or handle success
        navigate('/Success'); // Change this route to your success page
      })
      .catch((err) => {
        console.error('Error adding appointment:', err);
        // On error, navigate to the error component
        navigate('/Unsuccess'); // Change this route to your error component
      });
  }

  return (
    <section className="py-10 bg-gray-900 sm:py-16 lg:py-24">
      <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:items-stretch md:grid-cols-2 gap-x-12 lg:gap-x-20 gap-y-10">
          <div className="flex flex-col justify-between lg:py-5">
            <div>
              <motion.h2
                variants={fadeIn('down', 0.2)}
                initial="hidden"
                whileInView={'show'}
                viewport={{ once: false, amount: 0.7 }}
                className="text-3xl font-bold leading-tight text-white sm:text-4xl  lg:text-5xl font-serif"
              >
                Contact us today to schedule your appointment
              </motion.h2>
              <motion.p
                variants={fadeIn('zoom', 0.2)}
                initial="hidden"
                whileInView={'show'}
                viewport={{ once: false, amount: 0.7 }}
                className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-white font-montserrat"
              >
                Your mental health matters, and taking that first step toward
                professional help is an act of self-care. At Phoebe Compass
                Point, we are here to help you every step of the way with expert
                care that is both compassionate and personalized.
              </motion.p>
              
               <div className='mt-8'>
              <h2 className="text-2xl font-bold leading-tight text-white sm:text-xl lg:text-2xl font-serif">
               Follow these Instruction to set your Appointment
              </h2>
              <p className="max-w-xl mx-auto mt-4 text-base leading-relaxed text-white font-montserrat">
              We’re excited to help you get started. Follow these simple steps to easily set up your appointment
              </p>
            </div>

            <div className='mt-4'>
  <h2 className="sr-only">Steps</h2>

  <div>
    <ol
      className="grid grid-cols-1 divide-x divide-gray-100 overflow-hidden rounded-lg border border-gray-100 text-sm text-gray-500 sm:grid-cols-3"
    >
      <li className="flex items-center justify-center gap-2 p-4">
        <svg 
        className="size-7 shrink-0"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        
        >
  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
</svg>
        <p className="leading-none">
          <strong className="block font-medium text-gray-200"> Schedule </strong>
          <small className="mt-1 text-gray-200"> Select preferred Date. </small>
        </p>
      </li>

      <li className="relative flex items-center justify-center gap-2 bg-gray-800 p-4">
        <span
          className="absolute -left-2 top-1/2 hidden size-4 -translate-y-1/2 rotate-45 border border-gray-100 sm:block ltr:border-b-0 ltr:border-s-0 ltr:bg-white rtl:border-e-0 rtl:border-t-0 rtl:bg-gray-50"
        >
        </span>

        <span
          className="absolute -right-2 top-1/2 hidden size-4 -translate-y-1/2 rotate-45 border border-gray-100 sm:block ltr:border-b-0 ltr:border-s-0 ltr:bg-gray-50 rtl:border-e-0 rtl:border-t-0 rtl:bg-white"
        >
        </span>

        <svg 
         className="size-7 shrink-0"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        
        >
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
</svg>


        <p className="leading-none">
          <strong className="block font-medium text-gray-200"> Fill-up Form </strong>
          <small className="mt- text-gray-200"> Fill-up Personal Details </small>
        </p>
      </li>

      <li className="flex items-center justify-center gap-2 p-4">
      <svg
               className="size-7 shrink-0"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
      >
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59" />
</svg>

        <p className="leading-none">
          <strong className="block font-medium text-gray-200"> Signup </strong>
          <small className="mt-1 text-gray-200"> Click Signup Button. </small>
        </p>
      </li>
    </ol>
  </div>
</div>




            </div>
          </div>

          <div className="lg:pl-12">
            <div className="overflow-hidden bg-white rounded-md">
              <div className="p-6 sm:p-10">
                <h3 className="text-3xl font-semibold text-black font-serif">
                  Get In touch
                </h3>
                <p className="mt-4 text-base text-gray-600 font-montserrat">
                  Have questions or need support? Fill out the form, and we’ll
                  be in touch to guide you on your path to well-being.
                </p>

                <form
                  onSubmit={handleSubmit}
                  action="#"
                  method="POST"
                  className="mt-4"
                >
                  <div className="space-y-6">
                    <div>
                      <label
                        htmlFor="FullName"
                        className="text-base font-medium text-gray-900 font-serif"
                      >
                        Name
                      </label>
                      <div className="mt-2.5 relative">
                        <input
                          type="text"
                          name="FullName"
                          id="FullName"
                          placeholder="Enter your full name"
                          className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                          onChange={(e) =>
                            setValues({ ...values, FullName: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="Age"
                        className="text-base font-medium text-gray-900 font-serif"
                      >
                        Age
                      </label>
                      <div className="mt-2.5 relative">
                        <input
                          type="number"
                          id="Age"
                          name="Age"
                          onChange={(e) =>
                            setValues({ ...values, Age: e.target.value })
                          }
                          className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                          placeholder="Enter Age"
                          required
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="PhoneNumber"
                        className="text-base font-medium text-gray-900 font-serif"
                      >
                        Phone
                      </label>
                      <div className="mt-2.5 relative">
                        <PhoneInput
                          international
                          defaultCountry="US"
                          value={values.PhoneNumber}
                          onChange={(phoneNumber) =>
                            setValues({ ...values, PhoneNumber: phoneNumber })
                          }
                          className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                          placeholder="Enter your Phone Number"
                          required
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="EmailAddress"
                        className="text-base font-medium text-gray-900 font-serif"
                      >
                        Email address
                      </label>
                      <div className="mt-2.5 relative">
                        <input
                          type="text"
                          name="EmailAddress"
                          id="EmailAddress"
                          placeholder="Enter your Email Address"
                          className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                          onChange={(e) =>
                            setValues({
                              ...values,
                              EmailAddress: e.target.value
                            })
                          }
                          required
                        />
                      </div>
                    </div>

                    <div>
                    <button type="submit" class="inline-flex items-center justify-center w-full px-4 py-3 text-sm font-semibold text-white transition-all duration-200 bg-[#4ADE80] border border-transparent rounded-md hover:bg-[#34C759] focus:bg-[#34C759]">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
                </svg>
                Schedule Virtual Appointment
            </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contactus;
