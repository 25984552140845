import React, { useState, useEffect } from 'react';
import abt4 from '../../Assets/abt4.jpg'
import abt2 from '../../Assets/abt2.jpg'
import abt3 from '../../Assets/abt3.jpg'
import {motion} from 'framer-motion';
import {fadeIn} from '../../Variants'
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import 'react-phone-number-input/style.css'; // Import react-phone-number-input CSS
import PhoneInput from 'react-phone-number-input';
import Calendar from '../../Assets/Calendar.svg'
function Aboutus() {
  const [showModal, setShowModal] = useState(true); // Start with modal shown
  const navigate = useNavigate(); 
  const [values, setValues] = useState({
    FullName: '',
    PhoneNumber: '',
    EmailAddress: '',
    Age: ''
  });

  // Handle modal visibility timeout
  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(() => {
      setShowModal(true);
    }, 180000); // 180000 ms = 3 minutes
  };

function handleSubmit(e) {
  e.preventDefault();
  axios
    .post(
      'https://phoebe-compass-point-560accfa31d6.herokuapp.com/add_Appointment',
      values
    )
    .then((res) => {
      // On success, navigate to a success page or handle success
      navigate('/Success'); // Change this route to your success page
    })
    .catch((err) => {
      console.error('Error adding appointment:', err);
      // On error, navigate to the error component
      navigate('/Unsuccess'); // Change this route to your error component
    });
}


  return (

    
    <section className="overflow-hidden pt-4 pb-12 lg:pt-[50px] lg:pb-[60px] bg-gradient-to-b from-green-50 to-green-100 dark:bg-dark">
    <div className="container mx-auto p-4 ">
      <div className="flex flex-wrap items-center justify-between  -mx-4">
      <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
          <div className="mt-10 lg:mt-0">
            <motion.h2
              variants={fadeIn("down",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
             className="mb-2 text-3xl font-bold font-serif text-dark dark:text-white sm:text-[40px]/[48px]">
            Experience compassionate care tailored to you
            </motion.h2>
            <motion.p
            variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
             className="mb-5 mt-2 font-medium text-gray-600 font-montserrat">
            At Phoebe Compass Point, our mission is to provide compassionate, high-quality mental health care that empowers individuals to lead healthier and more fulfilling lives. 
            </motion.p>
            <div class="space-y-4 lg:pr-16 xl:pr-24 lg:col-span-2 lg:space-y-4">
                <motion.div
                   variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                 class="flex items-start">
                    <svg class="flex-shrink-0 text-green-500 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.05 4.575a1.575 1.575 0 1 0-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 0 1 3.15 0v1.5m-3.15 0 .075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 0 1 3.15 0V15M6.9 7.575a1.575 1.575 0 1 0-3.15 0v8.175a6.75 6.75 0 0 0 6.75 6.75h2.018a5.25 5.25 0 0 0 3.712-1.538l1.732-1.732a5.25 5.25 0 0 0 1.538-3.712l.003-2.024a.668.668 0 0 1 .198-.471 1.575 1.575 0 1 0-2.228-2.228 3.818 3.818 0 0 0-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0 1 16.35 15m.002 0h-.002" />
</svg>
                    <div class="ml-5">
                        <h3 class="text-xl font-semibold text-black font-serif">Compassion</h3>
                        <p class="mt-2 text-base text-gray-600 font-montserrat font-medium">We lead with empathy, listening to our clients’ needs and concerns with genuine care and understanding.</p>
                    </div>
                </motion.div>
                <motion.div
                 variants={fadeIn("zoom",0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                 class="flex items-start">
                    <svg class="flex-shrink-0 text-blue-600 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
</svg>
                    <div class="ml-5">
                        <h3 class="text-xl font-semibold text- font-serif">Collaboration</h3>
                        <p class="mt-2 text-base text-gray-600 font- font-medium">We believe in a team-based approach, working with other mental health providers to ensure you receive the comprehensive care you deserve.</p>
                    </div>
                </motion.div>

                <motion.div
   variants={fadeIn("zoom",0.4)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                 class="flex items-start">
                    <svg class="flex-shrink-0 text-red-500 w-9 h-9" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                    </svg>
                    <div class="ml-5">
                        <h3 class="text-xl font-semibold text-black font-serif">Personalized Care</h3>
                        <p class="mt-2 text-base text-gray-600 font-montserrat font-medium">Every treatment plan is crafted to fit the individual’s unique circumstances, promoting the best possible outcomes.</p>
                    </div>
                </motion.div>
            </div>
      
          </div>
        </div>
       
        <div className="w-full mt-4 px-4 lg:w-6/12">
  <div className="flex items-center -mx-3 sm:-mx-4">
  <div className="w-full px-3 sm:px-4 xl:w-1/2">
  <motion.div whileHover={{ scale: 1.05, rotate: 2 }} className="py-3 sm:py-4 relative">
    <div className="relative">
      <img
        src={abt4}
        alt="Anxiety"
        className="w-[300px] sm:w-[350px] md:w-[400px] h-[250px] sm:h-[250px] md:h-[300px] rounded-2xl shadow-lg"
      />
      <div className="absolute top-4 left-2 text-gray-600 bg-white font-semibold text-sm rounded-full px-2 py-1 font-montserrat">
        Depression
      </div>
      <p className="absolute bottom-2 left-2 right-2 text-white bg-gray-800 bg-opacity-70 rounded p-2 text-xs sm:text-xs md:text-sm font-montserrat">
  Depression is a common mental disorder characterized by persistent sadness and a lack of interest in activities.
</p>

    </div>
  </motion.div>

  <motion.div whileHover={{ scale: 1.05, rotate: -2 }} className="py-3 sm:py-1 relative">
    <div className="relative">
      <img
        src={abt2}
        alt="Image 2"
        className="w-[300px] sm:w-[350px] md:w-[400px] h-[250px] sm:h-[250px] md:h-[300px] rounded-2xl shadow-lg"
      />
      <div className="absolute top-4 left-2 text-gray-600 bg-white font-semibold text-sm rounded-full px-2 py-1 font-montserrat">
        Anxiety
      </div>
      <p className="absolute bottom-2 left-2 right-2 text-white bg-gray-800 bg-opacity-70 rounded p-2 text-xs sm:text-xs md:text-sm font-montserrat">
        Anxiety involves feelings of worry, fear, or unease, often about an upcoming event or uncertain outcome.
      </p>
    </div>
  </motion.div>
</div>


    <div className="w-full xl:w-1/2">
      <motion.div whileHover={{ scale: 1.05, rotate: 2 }} className="relative z-10 my-4 relative">
        <div className="relative">
          <img
            src={abt3}
            alt="Image 3"
            className="w-[300px] sm:w-[350px] md:w-[400px] h-[300px] sm:h-[300px] md:h-[400px] rounded-2xl shadow-lg"
          />
          <div className="absolute top-4 left-2 text-gray-600 bg-white font-semibold text-sm rounded-full px-2 py-1 font-montserrat">
            ADHD
          </div>
          <p className="absolute bottom-2 left-2 right-2 text-white bg-gray-800 bg-opacity-70 rounded p-2 text-xs sm:text-xs md:text-sm font-montserrat">
            ADHD is a neurodevelopmental disorder that affects attention, impulse control, and hyperactivity levels.
          </p>
        </div>
      </motion.div>
    </div>



  </div>
</div>

  {/* Modal Section */}
  {showModal && (
            <div className="modal modal-open">
  <div className="modal-box w-11/12 max-w-5xl rounded-none p-4">
  <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={handleCloseModal}>
                  ✕
                </button>
    <div className="max-w-2xl mt-2 p-4 mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-3xl font-serif">
              Set up Initial Appointment
            </h2>
            <p className="max-w-xl mx-auto mt-4 text-sm leading-relaxed text-gray-600 font-montserrat">
              Start Your Mental Wellness Journey Today Scheduling an Initial Assessment Consultation Now
            </p>
          </div>
          <div className="flex flex-col -mt-8 md:flex-row"> 
      {/* Left Side - Modal Content */}
      <div className="w-full md:w-1/2 p-6 flex items-center justify-center">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <form className="max-w-xl mx-auto mt-2" onSubmit={handleSubmit} action="#" method="POST">
            <div className="space-y-2">
              <div className="md:flex md:space-x-2">
                <div className="md:w-1/2">
                  <label htmlFor="FullName" className="text-base font-medium text-gray-900 font-serif">
                    Name
                  </label>
                  <div className="mt-2.5 relative">
                    <input
                      type="text"
                      name="FullName"
                      id="FullName"
                      placeholder="Full Name"
                      className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                      onChange={(e) => setValues({ ...values, FullName: e.target.value })}
                      required
                    />
                  </div>
                </div>

                <div className="md:w-1/2">
                  <label htmlFor="Age" className="text-base font-medium text-gray-900 font-serif">
                    Age
                  </label>
                  <div className="mt-2.5 relative">
                    <input
                      type="number"
                      id="Age"
                      name="Age"
                      onChange={(e) => setValues({ ...values, Age: e.target.value })}
                      className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                      placeholder="Enter Age"
                      required
                    />
                  </div>
                </div>
              </div>

              <div>
                <label htmlFor="PhoneNumber" className="text-base font-medium text-gray-900 font-serif">
                  Phone
                </label>
                <div className="mt-2.5 relative">
                  <PhoneInput
                    international
                    defaultCountry="US"
                    value={values.PhoneNumber}
                    onChange={(phoneNumber) => setValues({ ...values, PhoneNumber: phoneNumber })}
                    className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                    placeholder="Enter your Phone Number"
                    required
                  />
                </div>
              </div>

              <div>
                <label htmlFor="EmailAddress" className="text-base font-medium text-gray-900 font-serif">
                  Email address
                </label>
                <div className="mt-2.5 relative">
                  <input
                    type="text"
                    name="EmailAddress"
                    id="EmailAddress"
                    placeholder="Enter your Email Address"
                    className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:ring-orange-500 focus:border-orange-500 caret-orange-500 font-montserrat"
                    onChange={(e) => setValues({ ...values, EmailAddress: e.target.value })}
                    required
                  />
                </div>
              </div>

              <div>
                <button type="submit" className="inline-flex items-center justify-center w-full px-4 py-3 text-sm font-semibold text-white transition-all duration-200 bg-[#4ADE80] border border-transparent rounded-md hover:bg-[#34C759] focus:bg-[#34C759]">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-5 w-5 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" />
                  </svg>
                  Schedule Appointment
                </button>
              </div>
            </div>
          </form>

          <div className="flex items-center justify-start mt-2 sm:justify-center sm:px-0">
            <svg className="flex-shrink-0 w-5 h-5 text-gray-600 hidden lg:block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"> {/* Hidden on small screens */}
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
            </svg>
            <span className="ml-2 text-sm text-gray-600 font-montserrat">
              Your data is completely secured with us. We don’t share it with anyone.
            </span>
          </div>
        </div>
      </div>

      {/* Right Side - SVG Image */}
      <div className="w-full md:w-1/2 flex items-center justify-center p-6">
        <img
          src={Calendar}
          alt="Mental Wellness Illustration"
          className="w-full h-auto hidden md:block" 
        />
      </div>
    </div>
  </div>
</div>


          )}


  
      </div>
    </div>
  </section>
  )
}

export default Aboutus