import Aboutus from "../../components/WebsiteComponents/Aboutus";
import Howwork from '../../components/WebsiteComponents/Howwork'
export const About = () => {
    return (
        <> 
<Aboutus/>
<Howwork/>
    </>
       
    );
}