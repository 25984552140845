import React from 'react'
import {motion} from 'framer-motion';
import {fadeIn} from '../../Variants'

function Howwork() {
  return (
    <section class="py-10 bg-white sm:py-16 lg:py-16">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-2xl mx-auto text-center">
            <motion.h2 
             variants={fadeIn("down",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
            class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl font-serif">Why Choose Phoebe Compass Point?</motion.h2>
            <motion.p
             variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
             class="mt-2 text-base font-Montserrat text-gray-800 sm:text-xl font-montserrat">Our practice is led by Febbie, a board-certified Psychiatric Nurse Practitioner, who specializes in providing tailored treatment plans to meet the unique needs of each individual.</motion.p>
        </div>

        <div class="relative mt-12 lg:mt-20">
            <div class="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
                <img class="w-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/steps/2/curved-dotted-line.svg" alt="" />
            </div>

            <div class="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
                <motion.div
                 variants={fadeIn("zoom",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                >
                    <div class="flex items-center justify-center w-16 h-16 mx-auto bg-yellow-100 border-2 border-gray-200 rounded-full shadow">
                        <span class="text-xl font-semibold text-gray-700"> 1 </span>
                    </div>
                    <h3 class="mt-6 text-xl font-semibold leading-tight text-black md:mt-10 font-serif">Expert-Led Care</h3>
                    <p class="mt-2 text-sm font-semibold text-gray-600 font-montserrat">Led by Febbie, a board-certified Psychiatric Nurse Practitioner, who brings extensive experience and expertise in mental health care. Febbie ensures every patient receives the best possible treatment with a compassionate approach.</p>
                </motion.div>

                <motion.div
                  variants={fadeIn("zoom",0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                >
                    <div class="flex items-center justify-center w-16 h-16 mx-auto bg-red-100 border-2 border-gray-200 rounded-full shadow">
                        <span class="text-xl font-semibold text-gray-700"> 2 </span>
                    </div>
                    <h3 class="mt-6 text-xl font-semibold leading-tight text-black md:mt-10 font-serif">Personalized Treatment Plans</h3>
                    <p class="mt-2 text-sm font-semibold text-gray-600 font-montserrat">We create tailored treatment plans that are customized to meet the unique mental health needs of each individual. No two patients are the same, and neither are their care plans.</p>
                </motion.div>

                <motion.div
                    variants={fadeIn("zoom",0.4)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount:0.7}}
                >
                    <div class="flex items-center justify-center w-16 h-16 mx-auto bg-blue-100 border-2 border-gray-200 rounded-full shadow">
                        <span class="text-xl font-semibold text-gray-700"> 3 </span>
                    </div>
                    <h3 class="mt-6 text-xl font-semibold leading-tight text-black md:mt-10 font-serif">Holistic, Person-Centered Approach</h3>
                    <p class="mt-2 text-sm font-semibold text-gray-600 font-montserrat">At Phoebe Compass Point, we take a holistic and collaborative approach, working closely with patients and other mental health professionals to ensure comprehensive, empathetic care for long-term wellness.</p>
                </motion.div>
            </div>
        </div>
    </div>
</section>

  )
}

export default Howwork